import { Row } from "antd";
import { TFunction, withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { HeaderSection, LogoContainer } from "./styles";

const Header = ({ t }: { t: TFunction }) => {
  /*
  const [visible, setVisibility] = useState(false);

  
  const toggleButton = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    return (
      <>
        <CustomNavLinkSmall onClick={() => scrollTo("about")}>
          <Span>{t("About")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("mission")}>
          <Span>{t("Mission")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("product")}>
          <Span>{t("Product")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => scrollTo("contact")}
        >
          <Span>
            <Button>{t("Contact")}</Button>
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };
  */

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <img
              src="img/icons/logo.png"
              width="200px"
              height="64px"
              alt="O mundo das Anas"
            />
          </LogoContainer>
          <a href="https://www.instagram.com/omundodasanas/" target="_blank">
            <img
              src="img/icons/instagram.png"
              width="50px"
              height="50px"
              alt="Instagram"
            />
          </a>
          {/*
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={toggleButton}>
            <Outline />
          </Burger>
          */}
        </Row>
        {/*
        <Drawer closable={false} open={visible} onClose={toggleButton}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={toggleButton}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
        */}
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
